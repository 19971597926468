
<template>
  
    <header style="height:600px !important;">
      <div class="container" >
        <div class="navbar" id="app">
          <router-link to="/BusinessProfile">Home</router-link>
          <router-link to="/IntroduceCompany">회사소개</router-link>
          <router-link to="/BusinessProfile">주요사업</router-link>
          <router-link to="/#">News</router-link>
          <a href="#contact">Contact</a>
        </div>
        
      </div>
     
      <div class="header-content">
          <img src="../assets/images/대문3.jpg" alt="대문" id="headImg">
          <div class="welcome-message" style="top:55% !important;">
             Korea Biological Conservation Institute KBCI Inc.
          </div>
        </div>
        
        <a href="/" class="logo">
          <img src="../assets/images/logo.png" alt="로고">
        </a>
    
    </header>
    <br>
    <br>
  
    <body>
      <div id="mainBody">
  
  
        <section id="about">
    <div class="container">
      <num class="numbered-title">01.</num> &nbsp;&nbsp;<num style="font-size:30px;">회사 소개</num><br><br>
      <p>우리 한국생물보전연구소는 우리나라 토속어종에 대한 연구 및 종묘생산에 앞장서고 있으며<br>
       우리의 토속어를 아름다운 관상가치로써의 재평가를 통해 보다 구체적이고 본격적인 보전, 보호를 위해 설립된 회사입니다.</p><br>
      <div>
        <router-link to="/IntroduceCompany"><button class="custom-button">우리회사에 대해 자세히 알아보세요</button></router-link>
  
      </div>
      
    </div>
  </section>
  <br>
  <br>
  
  <section id="services">
    <div class="container">
      
      <num class="numbered-title">02.</num> &nbsp;&nbsp;<num style="font-size:30px;">주요 사업</num><br><br>
      <div class="service-list">
        <div class="service">
          <img src="../assets/images/service1.jpeg" alt="서비스 1" >
          <h4>토속•멸종위기 어류 
            <br>  종자생산/양식</h4>
      
        </div>
        <div class="service">
          <img src="../assets/images/service2.jpeg" alt="서비스 1">
          <h4>ODA사업</h4>
  
        </div>
        <div class="service">
          <img src="../assets/images/service3.jpg" alt="서비스 1">
          <h4>학술•연구 용역</h4>
        </div>
      </div>
      
    </div>
    
    
  </section>
  <!-- 뱀장어 프로젝트 -->
  <section id="services">
    <div class="container">
      
      &nbsp;&nbsp;<num style="font-size:30px;">Project</num><br><br>
      <!-- <div class="service-list">
        <div class="service">
          <img src="../assets/images/service4.jpg" alt="서비스 1">
          
        </div>
        <div class="service">
          <img src="../assets/images/service5.jpg" alt="서비스 1"><br>
          <h4>실뱀장어 생산 프로젝트</h4>
  
        </div>
        <div class="service">
          <img src="../assets/images/service6.jpg" alt="서비스 1">
      
        </div>
      </div> -->
      <!-- 슬라이드쇼 시작 -->
      <div class="slideshow-container">
    <div v-for="(imageGroup, index) in imageGroups" :key="index" v-show="index === currentSlide" class="w3-animate-opacity">
      <div class="image-group">
        <img v-for="(image, i) in imageGroup" :key="i" :src="image" class="slide-image" >
      </div>
    </div>
    <a class="prev" @click="prevSlide">&#10094;</a>
    <a class="next" @click="nextSlide">&#10095;</a>
  </div>
  
  <h4>실뱀장어 생산 프로젝트</h4>
  <!-- 슬라이드쇼 끝 -->
  
  
  
  <br>
  
    </div>
    
    <router-link to="/BusinessProfile"><button class="custom-button2">생보연의 주요사업 더 알아보기</button></router-link>
  </section>
  
  
  <br>
  <br>
  
  <!-- 고객사 -->
  <section id="clients">
      <div class="container">
        <num class="numbered-title">03.</num> &nbsp;&nbsp;<num style="font-size:30px;">Our Clients</num><br><br>
        <div class="client-logos">
          <a v-for="client in clients" :key="client.id"  target="_blank" class="client-logo">
            <img :src="client.logo" :alt="client.name" class="Clogo">
          </a>
        </div>
      </div>
    </section>
  <br>
  <br>
  <br>
  
  <section id="contact">
      <div class="container">
        <h2>Contact Us</h2>
  
        <div style="font:normal normal 400 12px/normal dotum, sans-serif; width:640px; height:392px; color:#333; position:relative; margin:auto;">
        <div style="height: 360px;"><a href="https://map.kakao.com/?urlX=495868.0&amp;urlY=915359.0&amp;name=%EC%B6%A9%EB%82%A8%20%EC%95%84%EC%82%B0%EC%8B%9C%20%EC%8B%A4%EC%98%A5%EB%A1%9C%20159-8&amp;map_type=TYPE_MAP&amp;from=roughmap" target="_blank">
        <img class="map" src="http://t1.daumcdn.net/roughmap/imgmap/cd4d58493e7245e09970be492131c2384282c242077a7d124541801b9e342136" width="638px" height="358px" style="border:1px solid #ccc;">
        </a>
        </div>
        <div style="overflow: hidden; padding: 7px 11px; border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 0px 0px 2px 2px; background-color: rgb(249, 249, 249);">
          <a href="https://map.kakao.com" target="_blank" style="float: left;">
        <img src="//t1.daumcdn.net/localimg/localimages/07/2018/pc/common/logo_kakaomap.png" width="72" height="16" alt="카카오맵" style="display:block;width:72px;height:16px">
        </a>
        <div style="float: right; position: relative; top: 1px; font-size: 11px;">
          <a target="_blank" href="https://map.kakao.com/?from=roughmap&amp;eName=%EC%B6%A9%EB%82%A8%20%EC%95%84%EC%82%B0%EC%8B%9C%20%EC%8B%A4%EC%98%A5%EB%A1%9C%20159-8&amp;eX=495868.0&amp;eY=915359.0" style="float:left;height:15px;padding-top:1px;line-height:15px;color:#000;text-decoration: none;">길찾기
        </a>
        </div>
        </div>
        </div>
        <br>
  
  
        <div class="contact-details">
          <div>
            <strong>Tel:</strong> &nbsp; 041-549-5800
          </div><br>
          <div>
            <strong>Email:</strong> &nbsp;  kbci@kbioc.com
          </div><br>
          <div>
            <strong>Fax:</strong> &nbsp; <a> 0504-382-2601</a>
          </div><br>
          <div>
            <strong>Address:</strong> &nbsp; 충청남도 아산시 실옥로 159-8 한국생물보전연구소
          </div><br>
        </div>
  
   
      </div>
    </section>
  
  
      </div>
  
      
    </body>
  <p></p>
   <footer class="site-footer">
      <div class="container">
        <div class="footer-content">
          <div>
            <h4>About Us</h4>
            <p>Our mission is to let people know that the beauty of nature and the value and value of native language in Korea are infinite.</p>
          </div>
          <div>
            <h4>Contact Info</h4>
            <ul>
              <li>Tel: 041-549-5800</li>
              <li>Email: kbci@kbioc.com</li>
              <li>Address: 충청남도 아산시 실옥로 159-8 한국생물보전연구소</li>
            </ul>
          </div>
  
        <div class="footer-bottom">
          <p>© 2024 KBCI. All Rights Reserved.</p><br>
          <p><a href="https://kr.freepik.com/free-photo/elegant-white-background-with-blue-wave-lines_15829504.htm#from_view=detail_alsolike">배경작가 starline 출처 Freepik
         </a></p>
        </div>
      </div>
      </div>
    </footer> 
  
  </template>
  
  <style>
  
  
  * { 
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: 'Montserrat', sans-serif;
  }
  
  header {
    margin: auto;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
  
  
  }
  
  .header-content {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  #headImg {
    width: 100%;
    height: 100%; /* 또는 100% */
    object-fit: cover;
    z-index: 1;
  }
  
  .welcome-message {
    position: absolute;
    top: 47%; /* 이미지 중앙으로 조정 */
    left: 50%; /* 이미지 중앙으로 조정 */
    transform: translate(-50%, -50%); /* 정확히 중앙으로 */
    color: #fefefe;
    font-size: 2.2em; /* 폰트 크기 조정 */
    z-index: 2;
    text-align: center;
    font-weight: 400; 
    background-color: rgba(180, 180, 238, 0.189);
  }
  
  .container {
    width: 100%;
    margin: auto;
    position: relative;
  }
  
  .logo {
   
    float: left;
    margin-left: 8%;
    margin-top: 2%;
    width: 170px;
    height: 170px;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    opacity: 1;
    z-index: 3; /* Higher z-index for the logo */
    /* opacity: 0.5; */
  }
  
  .logo img {
    width: 100%;
    height: 100%;
  
  }
  
  .navbar {
    background-color: rgba(180, 180, 238, 0.189);
    overflow: hidden;
    height: 26vh;
    /* height: 190px; */
    
    width: 100%;
    z-index: 2; /* Higher z-index for the navbar */
    position: absolute;
    top: 0;
    
    object-fit: cover;
  }
  
  .navbar a {
    float: right;
    margin-right: 5%;
    margin-top: 4.7%;
    display: block;
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 1.5% 2%;
    text-decoration: none;
    font-size:24px;
    font-weight: 400; 
  }
  
  .navbar router-link{
    float: right;
    margin-right: 5%;
    margin-top: 4.7%;
    display: block;
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 1.5% 2%;
    text-decoration: none;
    font-size:24px;
    font-weight: 400; 
  }
  .navbar:hover {
    background-color: white;
    color: rgb(0, 0, 0);
  
  
  }
  .navbar:hover a{
    color: black;
  
  }
  .navbar:hover router-link{
    color: black;
    cursor: pointer;
  
  }
  .navbar a:hover {
    color: rgb(79, 8, 244);
  }
  .navbar router-link:hover {
    color: rgb(79, 8, 244);
  }
  
  
  /* Media Query for smaller screens */
  @media screen and (max-width: 600px) {
    .navbar a {
      width: 100%;
      text-align: center;
    }
  }
  
  body {
    background: url('../assets/images/back.jpg') no-repeat center center fixed; 
    background-size: cover; /* 배경 이미지를 화면에 꽉 채우도록 */
  }
  
  #mainBody{
    /* border-style: solid; */
    border-color:rgb(129, 104, 182);
    border-width: 0px 1px;
    width:82%;
    height: auto;
    margin:auto;
  }
  
  
  section {
    padding: 10px 0;
    text-align: center;
  }
  
  section .container {
    width: 80%;
    margin: auto;
  }
  
  .service-list {
    display: flex;
    justify-content: space-around;
    padding: 5px;
  }
  
  .service {
    width: 30%;
    text-align: center;
  }
  
  .container p{
    font-size: 
    18px;
  }
  
  
  .service img {
    width: 100%;
    height: auto;
  }
  
  .custom-button {
    background-color: #8267cc; /* 버튼 배경색 */
    border: none;
    color: white; /* 글자 색상 */
    padding: 15px 32px; /* 상하 좌우 패딩 */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px; /* 버튼 모서리 둥글기 */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); /* 그림자 효과 */
    border: 2px solid #7162d4; /* 테두리 색상 */
  }
  .custom-button:hover{
    background-color: #ea8053;
  }
  .custom-button2:hover{
    background-color: #7162d4;
  }
  
  .custom-button2 {
    background-color: #ea8053; /* 버튼 배경색 */
    border: none;
    color: white; /* 글자 색상 */
    padding: 15px 32px; /* 상하 좌우 패딩 */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px; /* 버튼 모서리 둥글기 */
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); /* 그림자 효과 */
    border: 2px solid #d4c237; /* 테두리 색상 */
  }
  
  .numbered-title {
    font-size: 30px; /* 글자 크기 */
    color: #6f55b1f3; /* 글자 색상 */
    font-weight: bold; /* 글자 두께 */
    opacity: 0.7; /* 글자 투명도 */
   
  }
  
  /* 슬라이드 시작 */
  /* 슬라이드쇼 컨테이너 스타일링 */
  .image-group {
    display: flex;
    justify-content: center;
  }
  
  .slide-image {
    width: calc(100% / 2); /* 3개의 이미지가 같은 크기를 가지도록 설정 */
    height: 400px;
    padding: 0 4px; /* 이미지 사이의 간격 조정 */
  }
  
  
  .mySlides:first-of-type {
    display: block;
  }
  
  .slideshow-container {
    max-width: 1050px;
    position: relative;
    margin: auto;
  }
  
  /* 각 슬라이드 스타일링 */
  .mySlides {
    display: none;
    position: relative; /* 자식 요소인 버튼을 절대위치로 배치하기 위함 */
  }
  
  /* 이미지 스타일링 */
  .mySlides img {
    width: 100%; /* 슬라이드쇼 컨테이너의 너비에 맞게 조정 */
    height: 465px;
    /* height: auto;  */
  }
  
  /* 이전/다음 버튼 스타일링 */
  .prev, .next {
    cursor: pointer;
    position: absolute;
    top: 50%; /* 버튼을 수직 중앙에 위치 */
    transform: translateY(-50%); /* 수직 중앙 정렬 보정 */
    width: auto;
    padding: 16px;
    color: rgb(7, 7, 7);
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    z-index: 10;  /* 이미지 위에 버튼이 표시되도록 z-index 설정 */
    background-color: rgba(255, 255, 255, 0.8);
  }
  
  .prev {
    left: -50px;  /* 슬라이드 쇼 컨테이너 왼쪽 바깥으로 이동 */
  }
  
  .next {
    right: -50px;  /* 슬라이드 쇼 컨테이너 오른쪽 바깥으로 이동 */
  }
  
  /* 버튼 호버 효과 */
  .prev:hover, .next:hover {
    background-color: rgba(220, 100, 36, 0.8);
  }
  /* 슬라이드 끝 */
  
  /* 고객사 */
  #clients .container {
    text-align: center;
  }
  
  .client-logos {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .client-logo {
    margin: 10px;
    transition: transform 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 135px; /* 원의 크기 설정 */
    height: 135px; /* 원의 크기 설정 */
    border-radius: 50%; /* 원형 만들기 */
    background-color: white; /* 원 배경 색상 */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 그림자 추가 */
    overflow: hidden; /* 이미지가 원을 넘지 않도록 */
  }
  
  .client-logo:hover {
    transform: scale(1.1);
  }
  
  .Clogo {
    max-width: 70%; /* 로고 크기 조정 */
    max-height: 70%; /* 로고 크기 조정 */
    object-fit: contain; /* 이미지 비율 유지 */ 
  }
  
  /* footer */
  .site-footer {
    background-color: #020508;
    color: #ffffff;
    padding: 5px 0;
    font-size: 14px;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    margin-left: 75px;
  }
  
  .footer-content div {
    width: 30%;
  }
  
  .footer-content h4 {
    margin-bottom: 15px;
  }
  
  .footer-content p, .footer-content ul {
    font-size: 14px;
  }
  
  .footer-content ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-content ul li {
    margin-bottom: 10px;
  }
  
  .footer-content a {
    color: #ffffff;
    text-decoration: none;
  }
  
  .footer-bottom {
    text-align: center;
    margin-top: 20px;
    margin-right: 10px;
    
  }
  .footer-bottom p{
    margin-right: 200px;
    text-align: left;
  }
  
  .footer-bottom p a{
    text-align: left;
    margin-right:3px ;
    width: 100%;
  }
  .social-icons a {
    display: inline-block;
    margin-right: 10px;
  }
  
  .social-icons img {
    width: 30px;
    height: auto;
  }
  /* footer 끝 */
  
  </style>
  <script>
  
  export default {
  
    data() {
      return {
        currentSlide: 0,
        // 원래 이미지 배열 대신 이미지 그룹 배열을 사용합니다.
        clients: [
          { id: 1, name: "Client A", logo: require('@/assets/images/client-a.jpg'), url: "http://clienta.com" },
          { id: 2, name: "Client B", logo: require('@/assets/images/client-b.jpg'), url: "http://clientb.com" },
          { id: 3, name: "Client c", logo: require('@/assets/images/client-c.jpg'), url: "http://clientb.com" },
          { id: 4, name: "Client d", logo: require('@/assets/images/client-d.jpg'), url: "http://clientb.com" },
          { id: 5, name: "Client e", logo: require('@/assets/images/client-e.jpg'), url: "http://clientb.com" },
          { id: 6, name: "Client f", logo: require('@/assets/images/client-f.jpg'), url: "http://clientb.com" },
          { id: 7, name: "Client g", logo: require('@/assets/images/client-g.jpg'), url: "http://clientb.com" },
          { id: 8, name: "Client h", logo: require('@/assets/images/client-h.jpg'), url: "http://clientb.com" },
          { id: 9, name: "Client i", logo: require('@/assets/images/client-i.jpg'), url: "http://clientb.com" },
          { id: 10, name: "Client j", logo: require('@/assets/images/client-j.jpg'), url: "http://clientb.com" },
          
          // more clients
        ],
        imageGroups: [
          [
          require('@/assets/images/service4.jpg'),
          require('@/assets/images/service5.jpg'),
      
          ],
          [
            require('@/assets/images/service6.jpg'),
            require('@/assets/images/service7.jpg'),
        
          ],
          [
          require('@/assets/images/service8.jpg'),
          require('@/assets/images/service9.jpg')
          ],
          // 이 패턴을 계속할 수 있습니다.
        ]
      };
    },
    computed: {
      currentImageGroup() {
        // 현재 슬라이드에 해당하는 이미지 그룹을 반환합니다.
        return this.imageGroups[this.currentSlide];
      }
    },
    methods: {
      nextSlide() {
        this.currentSlide = (this.currentSlide + 1) % this.imageGroups.length;
      },
      prevSlide() {
        this.currentSlide = (this.currentSlide + this.imageGroups.length - 1) % this.imageGroups.length;
      }
    }
  };
  </script>
  